import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CryService } from '../crypto/cry.service';
import { UtilsService } from '../utils/utils.service';
import { LoginResponse, Login } from '../../interfaces/usuario.interface';


@Injectable({
  providedIn: 'root'
})
export class MetodosHttpService {

  constructor(
    private http: HttpClient,
    private enc: CryService,
    private utils: UtilsService
  ) {
  }


  postQuery<T>(query: string, body?: any) {

    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken()}`
    });

    if (!this.isEmpty(body)) {
      body = JSON.parse(this.enc.set(body));
    } else {
      body = null;
    }
    const url = `${environment.URL_SERVICIO}/${query}`;
    return this.http.post<T>(url, body, { headers }).pipe(
      map<T, T>(item => { return this.enc.get(JSON.stringify(item)) })
    );
  }

  getToken() {
    return this.utils.obtenerPorKey<Login>('usuario')?.token?.access_token;
  }
  isEmpty(obj: any) {
    if (obj) {
      return Object.keys(obj)?.length === 0;
    }
    return true;

  }
}
