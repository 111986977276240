import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import {  Login } from '../interfaces/usuario.interface';
import { UtilsService } from '../services/utils/utils.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private route: Router, private utils: UtilsService) { }
  canActivate() {
    const token = this.utils.obtenerPorKey<Login>('usuario');

    if (token) {
      return true;
    } else {
      this.route.navigate(['/auth']);
      return false;
    }

  }
}
