<!-- User profile -->
<div class="user-profile">
    <!-- User profile image -->
    <div class="profile-img">
        <img *ngIf="imagen == 'null'" src="assets/images/logo-icon.png" alt="user" />
        <img *ngIf="imagen" src="{{ imagen }}" alt="user" />


    </div>
    <!-- User profile text-->
    <div class="profile-text">
        <h5>{{usuario?.usuario.persona_nombre}} {{usuario?.usuario.persona_apellido}} </h5>
    </div>
</div>
<!-- End User profile text-->
<li class="nav-devider"></li>
<!-- Sidebar navigation-->
<nav class="sidebar-nav">
    <ul id="sidebarnav">
        <!-- First level menu -->
        <li [class.active]="showMenu === sidebarnavItem.title" *ngFor="let sidebarnavItem of sidebarnavItems"
            [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
            <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">{{sidebarnavItem.title}}</div>
            <a [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null"
                [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.tipo"
                (click)="addExpandClass(sidebarnavItem.title)">
                <i [ngClass]="[sidebarnavItem.icon]"></i>
                <span class="hide-menu">{{sidebarnavItem.title}}
                    <span *ngIf="sidebarnavItem.label != '' " [ngClass]="[sidebarnavItem.labelClass]">{{
                        sidebarnavItem.label }}</span>
                </span>
            </a>
            <a style="cursor: pointer;" [ngClass]="[sidebarnavItem.class]" (click)="logout()"
                *ngIf="sidebarnavItem.tipo===1">
                <i [ngClass]="[sidebarnavItem.icon]"></i>
                <span class="hide-menu">{{sidebarnavItem.title}}
                    <span *ngIf="sidebarnavItem.label != '' " [ngClass]="[sidebarnavItem.labelClass]">{{
                        sidebarnavItem.tipo }}</span>
                </span>
            </a>
            <!-- Second level menu -->
            <ul class="collapse" *ngIf="sidebarnavItem.submenu.length > 0"
                [ngClass]="{'in' : showMenu === sidebarnavItem.title }">
                <li *ngFor="let sidebarnavSubItem of sidebarnavItem.submenu"
                    [class.active]="showSubMenu === sidebarnavSubItem.title"
                    [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'active'">
                    <a [routerLink]="sidebarnavSubItem.submenu.length > 0 ? null : [sidebarnavSubItem.path]"
                        [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'router-link-active'"
                        [ngClass]="[sidebarnavSubItem.class]" *ngIf="!sidebarnavSubItem.extralink;"
                        (click)="addActiveClass(sidebarnavSubItem.title)">
                        <i [ngClass]="[sidebarnavSubItem.icon]"></i>
                        {{ sidebarnavSubItem.title }}
                    </a>
                    <!-- Third level menu -->
                    <ul class="collapse" *ngIf="sidebarnavSubItem.submenu.length > 0"
                        [ngClass]="{'in' : showSubMenu === sidebarnavSubItem.title }">
                        <li *ngFor="let sidebarnavSubsubItem of sidebarnavSubItem.submenu" routerLinkActive="active"
                            [ngClass]="[sidebarnavSubsubItem.class]">
                            <a [routerLink]="[sidebarnavSubsubItem.path]" *ngIf="!sidebarnavSubsubItem.extralink;"
                                [routerLinkActive]="sidebarnavSubsubItem.submenu.length > 0 ? '' : 'router-link-active'">
                                <i style="padding-left: 10px;" [ngClass]="[sidebarnavSubsubItem.icon]"></i>
                                {{ sidebarnavSubsubItem.title }}
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
</nav>
<!-- End Sidebar navigation -->