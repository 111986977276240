import { Component, AfterViewInit, EventEmitter, Output, OnInit } from '@angular/core';
import {
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Router } from '@angular/router';
import { UtilsService } from '../../services/utils/utils.service';
import { UsuariosService } from '../../services/usuarios/usuarios.service';
import { Login } from '../../interfaces/usuario.interface';
import { SocketsService } from '../../services/web-sockets/sockets.service';
declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit, OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();
  usuario: Login;
  public config: PerfectScrollbarConfigInterface = {};
  constructor(
    private wsuser: UsuariosService,
    private router: Router,
    private utils: UtilsService,
    private socket: SocketsService,
  ) { }

  // This is for Notifications
  notifications: Object[] = [];
  nuevas_notificaciones: Boolean;
  control_punto_rojo: Boolean = false;

  ngOnInit() {
    this.usuario = this.utils.obtenerPorKey<Login>('usuario');

    this.validar_notificaciones();

    this.socket.escuchar('canal-web-general').subscribe((res: any) => {
      // console.log(res);
      this.agregar_notificacion(res);
    });
  }

  validar_notificaciones(){
    if( this.notifications.length == 0 ){
      this.nuevas_notificaciones = false;
    }
    else{
      this.nuevas_notificaciones = true;
    }
  }

  agregar_notificacion(res){

    this.notifications.push(res);
    this.control_punto_rojo = true;
    this.nuevas_notificaciones = true;

  }

  notificaciones_visitadas(){
    this.control_punto_rojo = false;
  }




  ngAfterViewInit() { }
  logout() {
    this.wsuser.logout();
  }
}
