<header class="topbar">
    <nav class="d-flex top-navbar navbar-expand-md navbar-light">
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <div class="navbar-header">
            <a class="navbar-brand" href="index.html">
                <!-- Logo icon -->
                <b>
                    <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                    <!-- Dark Logo icon -->
                  
                    <!-- Light Logo icon -->
          
                </b>
                <!--End Logo icon -->
                <!-- Logo text -->
                <span>
                    <!-- dark Logo text -->
                    <img src="assets/images/logo-icon.png" alt="homepage" class="dark-logo" />
                    <!-- Light Logo text -->
                  
                </span>
            </a>
        </div>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <div class="navbar-collapse">
            <!-- ============================================================== -->
            <!-- toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav mr-auto mt-md-0">
                <!-- This is  -->
                <li (click)="toggleSidebar.emit()" class="nav-item m-l-10">
                    <a class="nav-link sidebartoggler waves-effect waves-dark" href="javascript:void(0)"><i
                            class="ti-menu"></i></a>
                </li>
                <!-- ============================================================== -->
                <!-- Comment -->
                <!-- ============================================================== -->
                <li class="nav-item dropdown" ngbDropdown placement="bottom-left">
                    <a ngbDropdownToggle class="nav-link text-muted text-muted waves-effect waves-dark"
                        href="javascript:void(0)">
                        <i class="mdi mdi-message"></i>
                        <div class="notify" *ngIf="control_punto_rojo">
                            <span class="heartbit"></span>
                            <!-- wakawaka -->
                            <span class="point"></span>
                        </div>
                    </a>
                    <div class="dropdown-menu dropdown-menu-left mailbox" ngbDropdownMenu>
                        <ul>
                            <li>
                                <div class="drop-title">Notificaciones</div>
                            </li>
                            <li>
                                <div class="message-center" [perfectScrollbar]="config">

                                    <span *ngIf="nuevas_notificaciones">
                                        <p *ngFor="let notification of notifications" (click)="notificaciones_visitadas()">
                                            <!-- Message -->
                                            <a [routerLink]="[notification.ruta]" style="padding: 10px 10px;">
                                                <div class="round {{notification.round}}">
                                                    <i class="{{notification.icon}}"></i>
                                                </div>
                                                <div class="mail-contnet">
                                                    <h5>{{notification.title}}</h5>
                                                    <span class="mail-desc">{{notification.subject}}</span>
                                                    <span class="time">{{notification.time}}</span>
                                                </div>
                                            </a>
                                        </p>
                                    </span>

                                    <span *ngIf="!nuevas_notificaciones" style="text-align: center;padding-top: 20px;display: block;">
                                        Sin nuevas notificaciones
                                    </span>

                                </div>
                            </li>
                            <!-- <li>
                                <a class="nav-link text-center" href="javascript:void(0);">
                                    <strong>Check all notifications</strong>
                                    <i class="fa fa-angle-right"></i>
                                </a>
                            </li> -->
                        </ul>
                    </div>
                </li>
                <!-- ============================================================== -->
                <!-- End Comment -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Messages -->
                <!-- ============================================================== -->
                <!-- <li class="nav-item dropdown" ngbDropdown placement="bottom-left">
                    <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark" href="javascript:void(0)">
                        <i class="mdi mdi-email"></i>
                        <div class="notify">
                            <span class="heartbit"></span>
                            <span class="point"></span>
                        </div>
                    </a>
                    <div class="dropdown-menu dropdown-menu-left mailbox" ngbDropdownMenu>
                        <ul>
                            <li>
                                <div class="drop-title">You have 4 new messages</div>
                            </li>
                            <li>
                                <div class="message-center" [perfectScrollbar]="config">
                                    <a href="#" *ngFor="let mymessage of mymessages">
                                        <div class="user-img">
                                            <img src="{{mymessage.useravatar}}" alt="user" class="img-circle"
                                                width="40">
                                            <span class="profile-status {{mymessage.status}} pull-right"></span>
                                        </div>
                                        <div class="mail-contnet">
                                            <h5>{{mymessage.from}}</h5>
                                            <span class="mail-desc">{{mymessage.subject}}</span>
                                            <span class="time">{{mymessage.time}}</span>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <a class="nav-link text-center" href="javascript:void(0);">
                                    <strong>See all e-Mails</strong>
                                    <i class="fa fa-angle-right"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li> -->
                <!-- ============================================================== -->
                <!-- End Messages -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Messages -->
                <!-- ============================================================== -->
                <!-- <li class="nav-item dropdown mega-dropdown" ngbDropdown [autoClose]="false">
                    <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark" href="javascript:void(0)">
                        <i class="mdi mdi-view-grid"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                        <ul class="mega-dropdown-menu row">
                            <li class="col-lg-3 col-xlg-2 m-b-30">
                                <h4 class="m-b-20">CAROUSEL</h4>

                                <ngb-carousel>
                                    <ng-template ngbSlide>
                                        <img src="assets/images/big/img1.jpg" class="img-responsive"
                                            alt="Random first slide">
                                        <div class="carousel-caption">
                                            <h3 class="text-white font-bold">First slide label</h3>

                                        </div>
                                    </ng-template>
                                    <ng-template ngbSlide>
                                        <img src="assets/images/big/img2.jpg" class="img-responsive"
                                            alt="Random second slide">
                                        <div class="carousel-caption">
                                            <h3 class="text-white font-bold">Second slide label</h3>

                                        </div>
                                    </ng-template>
                                    <ng-template ngbSlide>
                                        <img src="assets/images/big/img3.jpg" class="img-responsive"
                                            alt="Random third slide">
                                        <div class="carousel-caption">
                                            <h3 class="text-white font-bold">Third slide label</h3>

                                        </div>
                                    </ng-template>
                                </ngb-carousel>


                            </li>
                            <li class="col-lg-3 m-b-30">
                                <h4 class="m-b-20">ACCORDION</h4>

                                <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0"
                                    class="accordion nav-accordion">
                                    <ngb-panel title="Simple">
                                        <ng-template ngbPanelContent>
                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                                            richardson ad squid.
                                        </ng-template>
                                    </ngb-panel>
                                    <ngb-panel>
                                        <ng-template ngbPanelTitle>
                                            <span>&#9733;
                                                <b>Fancy</b> title &#9733;</span>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                                            richardson ad squid.
                                        </ng-template>
                                    </ngb-panel>
                                    <ngb-panel title="Disabled" [disabled]="true">
                                        <ng-template ngbPanelContent>
                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                                            richardson ad squid.
                                        </ng-template>
                                    </ngb-panel>
                                </ngb-accordion>
                            </li>


                            <li class="col-lg-3  m-b-30">
                                <h4 class="m-b-20">CONTACT US</h4>

                                <form>
                                    <div class="form-group">
                                        <input type="text" class="form-control" id="exampleInputname1"
                                            placeholder="Enter Name"> </div>
                                    <div class="form-group">
                                        <input type="email" class="form-control" placeholder="Enter email"> </div>
                                    <div class="form-group">
                                        <textarea class="form-control" id="exampleTextarea" rows="3"
                                            placeholder="Message"></textarea>
                                    </div>
                                    <button type="submit" class="btn btn-info">Submit</button>
                                </form>
                            </li>
                            <li class="col-lg-3 col-xlg-4 m-b-30">
                                <h4 class="m-b-20">List style</h4>

                                <ul class="list-style-none">
                                    <li>
                                        <a href="javascript:void(0)">
                                            <i class="fa fa-check text-success"></i> You can give link</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">
                                            <i class="fa fa-check text-success"></i> Give link</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">
                                            <i class="fa fa-check text-success"></i> Another Give link</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">
                                            <i class="fa fa-check text-success"></i> Forth link</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">
                                            <i class="fa fa-check text-success"></i> Another fifth link</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </li> -->
                <!-- ============================================================== -->
                <!-- End Messages -->
                <!-- ============================================================== -->
            </ul>
            <!-- ============================================================== -->
            <!-- User profile and search -->
            <!-- ============================================================== -->
            <ul class="navbar-nav my-lg-0">
                <!-- ============================================================== -->
                <!-- Search -->
                <!-- ============================================================== -->
                <!-- <li class="nav-item hidden-sm-down search-box">
                    <a class="nav-link hidden-sm-down text-muted waves-effect waves-dark" href="javascript:void(0)">
                        <i class="ti-search"></i>
                    </a>
                    <form class="app-search">
                        <input type="text" class="form-control" placeholder="Search & enter">
                        <a class="srh-btn">
                            <i class="ti-close"></i>
                        </a>
                    </form>
                </li> -->
                <!-- ============================================================== -->
                <!-- Profile -->
                <!-- ============================================================== -->
                <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
                    <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark"
                        href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img src="assets/images/favicon.png" alt="user" class="profile-pic" />
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                        <ul class="dropdown-user">
                            <li>
                                <div class="dw-user-box">
                                    <div class="u-img">
                                        <img src="assets/images/favicon.png" alt="user">
                                    </div>
                                    <div class="u-text">
                                        <h4> {{usuario?.usuario.persona_nombre}} {{usuario?.usuario.persona_apellido}} </h4>
                                        <p class="text-muted">{{usuario?.usuario.persona_correo}}  </p>
                                        <!-- <a href="pages-profile.html" class="btn btn-rounded btn-danger btn-sm">Ver 
                                            Perfil</a> -->
                                    </div>
                                </div>
                            </li>
                            <li role="separator" class="divider"></li>
                            <!-- <li>
                                <a href="#">
                                    <i class="ti-user"></i> Mi Perfil</a>
                            </li> -->

                            <!-- <li role="separator" class="divider"></li>

                            <li role="separator" class="divider"></li> -->
                            <li>
                                <a class="logout-rex" (click)="logout()">
                                    <i class="fa fa-power-off"></i> Logout</a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</header>