import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [

  {
    path: '',
    title: 'Dashboard',
    icon: 'mdi mdi-gauge',
    class: 'has-arrow',
    label: '',
    labelClass: 'label label-rouded label-themecolor',

    extralink: false,
    submenu: [
      {
        path: '/qhse/dashboard',
        title: 'Equipos',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/qhse/dashboard-videos',
        title: 'Videos institucionales',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },

    ],
    perfil: [1, 5]

  },
  {
    path: '',
    title: 'Usuarios',
    icon: 'mdi mdi-account-circle',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    perfil: [1, 5],
    submenu: [
      {
        path: '/qhse/usuarios/listar',
        title: 'Listado de usuarios',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/qhse/usuarios/registrar',
        title: 'Registrar usuario',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },


    ]
  },

  {
    path: '/qhse/equipos',
    title: 'Equipos',
    icon: 'mdi mdi-monitor',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    perfil: [1],

    submenu: []
  },
  {
    path: '/qhse/equipos-duplicados',
    title: 'Equipos duplicados',
    icon: 'mdi mdi-cellphone-link',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    perfil: [1],

    submenu: []
  },
  {
    path: '/qhse/videos-institucionales',
    title: 'Videos institucionales',
    icon: 'mdi mdi-youtube-play',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    perfil: [1],

    submenu: []
  },

  // {
  //     path: '',
  //     title: 'Síntomas',
  //     icon: 'mdi mdi-buffer',
  //     class: 'has-arrow',
  //     label: '',
  //     labelClass: '',
  //     extralink: false,
  //     perfil:[1,5],

  //     submenu: [
  //         {
  //             path: '/qhse/sintomas/listar-sintomas',
  //             title: 'Listar síntomas',
  //             icon: '',
  //             class: '',
  //             label: '',
  //             labelClass: '',
  //             extralink: false,
  //             submenu: []
  //         },
  //         {
  //             path: '/qhse/sintomas/listar-djd',
  //             title: 'Listar DJD',
  //             icon: '',
  //             class: '',
  //             label: '',
  //             labelClass: '',
  //             extralink: false,
  //             submenu: []
  //         },


  //     ]
  // },

  {
    path: '',
    title: 'Correos',
    icon: 'mdi mdi-email',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    perfil: [1],

    submenu: [
      {
        path: '/qhse/correo/listar',
        title: 'Listado de correos',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/qhse/correo/registrar',
        title: 'Registrar Correo',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },


    ]
  },


  {
    path: '',
    title: 'Consejos',
    icon: 'mdi mdi-file-multiple',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    perfil: [1],

    submenu: [
      {
        path: '/qhse/consejos/listar-consejos',
        title: 'Listado de consejos',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/qhse/consejos/registrar-consejos',
        title: 'Registrar consejos',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },


    ]
  },

  {
    path: '',
    title: 'Empresa',
    icon: 'mdi mdi-factory',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    perfil: [1],

    submenu: [
      {
        path: '/qhse/empresa/listar-empresa',
        title: 'Listado de empresas',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/qhse/empresa/registrar-empresa',
        title: 'Registrar empresa',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },


    ]
  },

  {
    path: '',
    title: 'Lugar de trabajo',
    icon: 'mdi mdi-map-marker',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    perfil: [1],

    submenu: [
      {
        path: '/qhse/lugar-trabajo/listar-lugar',
        title: 'Listado de lugar de trabajo',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/qhse/lugar-trabajo/registrar-lugar',
        title: 'Registrar lugar de trabajo',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },


    ]
  },

  /*
      {
          path: '',
          title: 'Bloqueados',
          icon: 'mdi  mdi-block-helper',
          class: 'has-arrow',
          label: '',
          labelClass: '',
          extralink: false,
          submenu: [
              {
                  path: '/qhse/bloqueados/listar-bloqueados',
                  title: 'Listado de bloqueados',
                  icon: '',
                  class: '',
                  label: '',
                  labelClass: '',
                  extralink: false,
                  submenu: []
              },



          ]
      }, */

  {
    path: '/qhse/log',
    title: 'Logs',
    icon: 'mdi mdi-file-check',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    perfil: [1],

    submenu: []
  },
  {
    tipo: 1,
    title: 'Cerrar Sesión',
    icon: 'mdi mdi-power',
    class: '',
    label: '',
    labelClass: '',
    perfil: [1, 5],
    extralink: false,

    submenu: [
    ]
  }
];
