import { Injectable, OnDestroy } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { tap } from 'rxjs/operators';
import { SOCKET_CONFIGURAR } from '../../paths/paths';



@Injectable({
  providedIn: 'root'
})
export class SocketsService {

  constructor(
    private socket: Socket,
  ) {
    this.verificarConexion();
   }


  public socketEstado = true;
  verificarConexion() {
    // console.log('incio sockets')
    this.socket.on('connect', () => {
      // console.log('conectado');

    });

    this.socket.on('disconnect', () => {
      // console.log('desconectado del servidor ****');
      this.socketEstado = false;

    });

  }

  emitir(evento: string, payload?: any, callback?: (res: any) => void) {
    this.socket.emit(evento, payload, callback);
    // console.log('emitiendo socket  => ' + evento);
  }

  escuchar(evento: string) {
    return this.socket.fromEvent(evento).pipe(tap(() => {
      // console.log('notificacion entrando de => ' + evento)
    }));
  }

  configurarUsuario() {
    const token = localStorage.getItem('token');
    this.emitir(SOCKET_CONFIGURAR, token);

  }

}
