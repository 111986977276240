import { Injectable } from '@angular/core';
import { FormGroup, ValidationErrors, AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() {

  }


  guardar(key: string, value: any) {
    const json = JSON.stringify({ value });
    localStorage.setItem(key, json);

  }

  obtenerPorKey<T>(key: string): T {
    return JSON.parse(localStorage.getItem(key))?.value;
  }

  eliminarPorKey(key: string) {
    localStorage.removeItem(key);
  }

  esValido(nombre: string, form: FormGroup) {
    return form.controls[nombre]?.errors && form.controls[nombre].touched;
  }
  
  campoIguales(campo1: string, campo2: string): ValidationErrors | null {
    return (control: FormGroup) => {
      const pass1 = control.get(campo1)?.value;
      const pass2 = control.get(campo2)?.value;
      if (pass1 !== pass2) {
        control.get(campo2)?.setErrors({
          noIguales: true
        })
        return {
          noIguales: true
        }
      }
      control.get(campo2)?.setErrors(null)
      return null
    }
  }

  getBase64(file: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  validateDocumento(control: AbstractControl): { [key: string]: any } | null {
    if (control.value && control.value.length !== 8) {
      return { 'docNumberInvalid': true };
    }
    return null;
  }
  validatePhone(control: AbstractControl): { [key: string]: any } | null {
    if (control.value && control.value.length !== 9) {
      return { 'phoneNumberInvalid': true };
    }
    return null;
  }
}
