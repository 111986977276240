import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CryService {

  constructor() { }

  /* -------------Javascript code example-----------------*/
  CryptoJSAesJson = {
    stringify: (cipherParams: any) => {
      var j: any = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
      if (cipherParams.iv) j.iv = cipherParams.iv.toString();
      if (cipherParams.salt) j.s = cipherParams.salt.toString();
      return JSON.stringify(j);
    },
    parse: (jsonStr: any) => {
      var j = JSON.parse(jsonStr);
      var cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) });
      if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
      if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
      return cipherParams;
    }
  }


  set(value: any) {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(value), environment.KEY_CRY, { format: this.CryptoJSAesJson }).toString();
    return encrypted;
  }

  get(value: any) {
    const decrypted = JSON.parse(CryptoJS.AES.decrypt(value, environment.KEY_CRY, { format: this.CryptoJSAesJson }).toString(CryptoJS.enc.Utf8));
    return decrypted;
  }
}
