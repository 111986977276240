import { Component, AfterViewInit, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ROUTES } from './menu-items';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilsService } from '../../services/utils/utils.service';
import { Usuario, Login } from '../../interfaces/usuario.interface';
import { UsuariosService } from '../../services/usuarios/usuarios.service';
declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  imagen = '';
  showSubMenu = '';
  usuario: Login;
  public sidebarnavItems: any[];
  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private util: UtilsService,
    private wsuser: UsuariosService,
  ) { }
  // End open close
  ngOnInit() {

    this.usuario = this.util.obtenerPorKey<Login>('usuario');
    const perfil = +this.usuario.usuario.perfil_id;
    this.sidebarnavItems = ROUTES.filter(sidebarnavItem => {
      return sidebarnavItem.perfil.includes(perfil);
    });
  }
  logout(){
    this.wsuser.logout();
  }
}
