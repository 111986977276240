import { Component, OnInit } from '@angular/core';
import { CryService } from './services/crypto/cry.service';
import { SocketsService } from './services/web-sockets/sockets.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  constructor(
    // private socket: SocketsService
    ) {


  }
  ngOnInit() {
    // console.log("suscrito canal-web-general")
    // this.socket.escuchar('canal-web-general').subscribe((res: any) => {
    //   console.log(res);
    // });



  }
}
