export const WS_USUARIO_LISTAR = 'persona/listarPersona';
export const WS_USUARIO_EDITAR = 'persona/editarPersona';
export const WS_USUARIO_REGISTRAR = 'persona/registrarPersona';
export const WS_USUARIO_VER = 'persona/detallePersona';
export const WS_USUARIO_ESTADO = 'persona/estadoPersona';
export const WS_LOGIN = 'auth/loginWeb';
export const WS_RENOVAR_CUENTA = 'auth/renovarPassword';
export const WS_LISTAR_TIPO_DOCUMENTOS = 'documento/listarTipoDocumento';
export const WS_LISTAR_PERFIL = 'perfil/listarPerfil';
export const WS_LISTAR_EMPRESAS = 'empresa/listarEmpresa';
export const WS_LISTAR_LUGAR_TRABAJO = 'publico/listarLugarTrabajo';
export const WS_LISTAR_LUGAR_TRABAJO_USUARIO = 'lutra/listarLugarTrabajo';
export const WS_LISTAR_LOGS = 'log/listarLog';
export const WS_LISTAR_CORREOS = 'notificacion/listarCorreo';
export const WS_REGISTRAR_CORREO = 'notificacion/registrarCorreo';
export const WS_ELIMINAR_CORREO = 'notificacion/borrarCorreo';
export const WS_RENOVAR_TOKEN = 'auth/refresh';
export const WS_DASHBOAD_DATA = 'dashboard/graficoRegistradosActivos';
export const WS_LISTAR_SINTOMAS = 'sintoma/listarSintomas';
export const WS_LISTAR_DJD = 'sintoma/listarDJDAdmin';
export const WS_DETALLE_DJD = 'sintoma/detalleDJDAdmin';
export const WS_LISTAR_CONSEJOS = 'consejo/listarConsejoTotal';
export const WS_REGISTRAR_CONSEJOS = 'consejo/registrarConsejo';
export const WS_EDITAR_CONSEJOS = 'consejo/editarConsejo';
export const WS_ESTADO_CONSEJOS = 'consejo/estadoConsejo';
export const WS_VER_CONSEJOS = 'consejo/detalleConsejo';
export const WS_LISTAR_BLOQUEADOS = 'medico/listarBloqueados';
export const WS_VER_DETALLE_BLOQUEADO = 'medico/detalleBloqueo';
export const WS_CAMBIAR_ESTADO_BLOQUEADO = 'medico/estadoBloqueo';

export const WS_LISTAR_EMPRESA = 'empresa/listarEmpresaTotal';
export const WS_REGISTRAR_EMPRESA = 'empresa/registrarEmpresa';
export const WS_EDITAR_EMPRESA = 'empresa/editarEmpresa';
export const WS_VER_EMPRESA = 'empresa/detalleEmpresa';
export const WS_ESTADO_EMPRESA = 'empresa/estadoEmpresa';

export const WS_LISTAR_LUGAR_TRABAJO_ADMIN = 'lutra/listarLugarTrabajoTotal';
export const WS_REGISTRAR_LUGAR_TRABAJO = 'lutra/registrarLugarTrabajo';
export const WS_EDITAR_LUGAR_TRABAJO = 'lutra/editarLugarTrabajo';
export const WS_VER_LUGAR_TRABAJO = 'lutra/detalleLugarTrabajo';
export const WS_ESTADO_LUGAR_LUGAR_TRABAJO= 'lutra/estadoLugarTrabajo';


export const SOCKET_CONFIGURAR = 'conectar';


export const WS_HABILITAR_USUARIO_OPERATIVO_COMORBILIDAD = 'persona/estadoComorbilidadPersona';

export const WS_LISTAR_EQUIPOS_QR = 'equipo/listarEquiposAdmin';
export const WS_LISTAR_EQUIPOS_DUPLICADOS = 'equipo/listarEquiposRepetidos';
export const WS_LISTAR_VIDEOS_COMPLETADOS = 'video/listarVideosCompletados';
export const WS_VIDEOS_POR_DIA = 'video/videosPorDia';
