// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //URL_SERVICIO: 'http://192.168.0.103:8000/api',
  URL_SERVICIO: 'https://qhse-ws-qa.wydnex.com/api',
  // URL_SERVICIO: 'http://localhost:80/qhse_covid_ws/public/api',
  KEY_CRY: '123456',
  // KEY_CRY: 'qhseprodpluspetrol',
  URL_SOCKET:'https://qhse-node.wydnex.com'

};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
