import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthGuard } from './guards/auth.guard';

export const routes: Routes = [

    {
        canActivate: [AuthGuard],
        path: 'qhse',
        loadChildren: () => import('./pages/qhse/qhse.module').then(m => m.QhseModule)

    },
    {
        path: 'auth',
        loadChildren: () => import('./pages/authentication/authentication.module').then(m => m.AuthenticationModule)

    },

    { path: '**', redirectTo: 'qhse', pathMatch: 'full' },

];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true }), NgbModule],
    exports: [RouterModule]
})
export class AppRoutingModule { }
