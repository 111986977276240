import { Injectable } from '@angular/core';
import { MetodosHttpService } from '../http/metodos-http.service';
import { WS_LOGIN, WS_RENOVAR_CUENTA, WS_LISTAR_TIPO_DOCUMENTOS, WS_LISTAR_PERFIL, WS_LISTAR_EMPRESAS, WS_RENOVAR_TOKEN, WS_LISTAR_LUGAR_TRABAJO, WS_LISTAR_LUGAR_TRABAJO_USUARIO, WS_HABILITAR_USUARIO_OPERATIVO_COMORBILIDAD } from '../../paths/paths';
import { LoginResponse } from '../../interfaces/usuario.interface';
import { WSUsuarioListar } from '../../interfaces/ws-usuario-listar';
import { UtilsService } from '../utils/utils.service';
import { Router } from '@angular/router';

import {
  WS_USUARIO_LISTAR,
  WS_USUARIO_EDITAR,
  WS_USUARIO_REGISTRAR,
  WS_USUARIO_VER,
  WS_USUARIO_ESTADO
} from '../../paths/paths';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  constructor(
    private http: MetodosHttpService,
    private utils: UtilsService,
    private router: Router
  ) { }


  login(body: any) {
    return this.http.postQuery<LoginResponse>(WS_LOGIN, body);
  }

  recuperarPassword(body: any) {
    return this.http.postQuery<any>(WS_RENOVAR_CUENTA, body);
  }

  listarUsuario(body: any) {

    // return this.http.postQuery<WSUsuarioListar>(WS_USUARIO_LISTAR, {});
    return this.http.postQuery<WSUsuarioListar>(WS_USUARIO_LISTAR, body);
  }

  editarUsuario(body: any) {

    return this.http.postQuery(WS_USUARIO_EDITAR, body);
  }

  registrarUsuario(body: any) {

    return this.http.postQuery(WS_USUARIO_REGISTRAR, body);
  }

  verUsuario(body: any) {
    return this.http.postQuery<any>(WS_USUARIO_VER, body);
  }

  estadoUsuario(body: any) {

    return this.http.postQuery(WS_USUARIO_ESTADO, body);
  }

  habilitarUsuarioOperativoComorbilidad(body: any) {

    return this.http.postQuery(WS_HABILITAR_USUARIO_OPERATIVO_COMORBILIDAD, body);
  }

  listarTipoDcoumentos() {
    return this.http.postQuery(WS_LISTAR_TIPO_DOCUMENTOS, null);
  }

  listarPerfiles() {
    return this.http.postQuery(WS_LISTAR_PERFIL, null);
  }
  listarEmpresas() {
    return this.http.postQuery(WS_LISTAR_EMPRESAS, null);
  }


  listarLugarTrabajo() {
    return this.http.postQuery(WS_LISTAR_LUGAR_TRABAJO, null);
  }

  listarLugarTrabajoAdmin() {
    return this.http.postQuery(WS_LISTAR_LUGAR_TRABAJO_USUARIO, null);
  }

  renuevaToken() {
    return this.http.postQuery(WS_RENOVAR_TOKEN, null);
  }

  logout() {

    this.utils.eliminarPorKey('usuario');
    this.router.navigate(['/auth/login']);
  }
}
